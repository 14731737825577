import { createContext, useContext, useState, ReactNode } from 'react';

interface CheckoutContextType {
  isCheckoutOpen: boolean;
  setCheckoutOpen: (open: boolean) => void;
}

const CheckoutContext = createContext<CheckoutContextType | undefined>(undefined);

export function CheckoutProvider({ children }: { children: ReactNode }) {
  const [isCheckoutOpen, setCheckoutOpen] = useState(false);

  return (
    <CheckoutContext.Provider value={{ isCheckoutOpen, setCheckoutOpen }}>
      {children}
    </CheckoutContext.Provider>
  );
}

export function useCheckout() {
  const context = useContext(CheckoutContext);
  if (context === undefined) {
    throw new Error('useCheckout must be used within a CheckoutProvider');
  }
  return context;
}

import { toast } from 'react-hot-toast';

interface PricingData {
  [key: string]: {
    [key: number]: number;
  };
}

// Pricing data organized by size and quantity
const pricingData: PricingData = {
  // Format: "widthxheight": { quantity: price }
  
    "1x1": {
        "50": 1.02,
        "100": 0.56,
        "200": 0.325,
        "300": 0.2433,
        "500": 0.174,
        "1000": 0.117,
        "2000": 0.084,
        "3000": 0.071,
        "5000": 0.0586,
        "10000": 0.0464
    },
    "1x2": {
        "50": 1.06,
        "100": 0.6,
        "200": 0.36,
        "300": 0.2733,
        "500": 0.2,
        "1000": 0.141,
        "2000": 0.1045,
        "3000": 0.09,
        "5000": 0.0758,
        "10000": 0.0617
    },
    "1x3": {
        "50": 1.1,
        "100": 0.63,
        "200": 0.385,
        "300": 0.3,
        "500": 0.224,
        "1000": 0.162,
        "2000": 0.123,
        "3000": 0.1073,
        "5000": 0.0916,
        "10000": 0.0756
    },
    "1x4": {
        "50": 1.14,
        "100": 0.66,
        "200": 0.415,
        "300": 0.3233,
        "500": 0.248,
        "1000": 0.181,
        "2000": 0.1405,
        "3000": 0.1237,
        "5000": 0.1064,
        "10000": 0.0887
    },
    "1x5": {
        "50": 1.16,
        "100": 0.69,
        "200": 0.44,
        "300": 0.3467,
        "500": 0.268,
        "1000": 0.2,
        "2000": 0.1575,
        "3000": 0.139,
        "5000": 0.1206,
        "10000": 0.1013
    },
    "1x6": {
        "50": 1.2,
        "100": 0.72,
        "200": 0.46,
        "300": 0.37,
        "500": 0.29,
        "1000": 0.219,
        "2000": 0.1735,
        "3000": 0.154,
        "5000": 0.1344,
        "10000": 0.1133
    },
    "1x7": {
        "50": 1.22,
        "100": 0.74,
        "200": 0.485,
        "300": 0.3933,
        "500": 0.31,
        "1000": 0.236,
        "2000": 0.189,
        "3000": 0.1687,
        "5000": 0.1456,
        "10000": 0.1251
    },
    "1x8": {
        "50": 1.26,
        "100": 0.77,
        "200": 0.51,
        "300": 0.4133,
        "500": 0.33,
        "1000": 0.254,
        "2000": 0.2045,
        "3000": 0.1827,
        "5000": 0.1606,
        "10000": 0.1365
    },
    "1x9": {
        "50": 1.28,
        "100": 0.8,
        "200": 0.53,
        "300": 0.4333,
        "500": 0.348,
        "1000": 0.271,
        "2000": 0.2195,
        "3000": 0.1967,
        "5000": 0.1732,
        "10000": 0.1476
    },
    "1x10": {
        "50": 1.32,
        "100": 0.82,
        "200": 0.555,
        "300": 0.4567,
        "500": 0.366,
        "1000": 0.287,
        "2000": 0.234,
        "3000": 0.2103,
        "5000": 0.1856,
        "10000": 0.1586
    },
    "1x11": {
        "50": 1.44,
        "100": 0.94,
        "200": 0.66,
        "300": 0.5567,
        "500": 0.46,
        "1000": 0.37,
        "2000": 0.3075,
        "3000": 0.279,
        "5000": 0.2484,
        "10000": 0.2225
    },
    "1x12": {
        "50": 1.48,
        "100": 0.97,
        "200": 0.69,
        "300": 0.5833,
        "500": 0.484,
        "1000": 0.392,
        "2000": 0.3265,
        "3000": 0.2963,
        "5000": 0.2642,
        "10000": 0.2405
    },
    "1x13": {
        "50": 1.5,
        "100": 1.0,
        "200": 0.72,
        "300": 0.61,
        "500": 0.508,
        "1000": 0.413,
        "2000": 0.345,
        "3000": 0.3137,
        "5000": 0.28,
        "10000": 0.2585
    },
    "1x14": {
        "50": 1.54,
        "100": 1.03,
        "200": 0.745,
        "300": 0.6333,
        "500": 0.532,
        "1000": 0.433,
        "2000": 0.363,
        "3000": 0.3307,
        "5000": 0.2954,
        "10000": 0.2765
    },
    "1x15": {
        "50": 1.58,
        "100": 1.06,
        "200": 0.775,
        "300": 0.66,
        "500": 0.554,
        "1000": 0.454,
        "2000": 0.381,
        "3000": 0.3473,
        "5000": 0.3106,
        "10000": 0.2945
    },
    "1x16": {
        "50": 1.62,
        "100": 1.1,
        "200": 0.8,
        "300": 0.6833,
        "500": 0.578,
        "1000": 0.474,
        "2000": 0.399,
        "3000": 0.3637,
        "5000": 0.3256,
        "10000": 0.3125
    },
    "1x17": {
        "50": 1.64,
        "100": 1.13,
        "200": 0.825,
        "300": 0.71,
        "500": 0.6,
        "1000": 0.494,
        "2000": 0.4165,
        "3000": 0.38,
        "5000": 0.3406,
        "10000": 0.3305
    },
    "2x1": {
        "50": 1.06,
        "100": 0.61,
        "200": 0.375,
        "300": 0.2867,
        "500": 0.216,
        "1000": 0.154,
        "2000": 0.117,
        "3000": 0.1017,
        "5000": 0.0866,
        "10000": 0.0713
    },
    "2x2": {
        "50": 1.16,
        "100": 0.69,
        "200": 0.445,
        "300": 0.3567,
        "500": 0.278,
        "1000": 0.209,
        "2000": 0.165,
        "3000": 0.1463,
        "5000": 0.1274,
        "10000": 0.1072
    },
    "2x3": {
        "50": 1.24,
        "100": 0.77,
        "200": 0.51,
        "300": 0.4167,
        "500": 0.334,
        "1000": 0.258,
        "2000": 0.2085,
        "3000": 0.1867,
        "5000": 0.1642,
        "10000": 0.1398
    },
    "2x4": {
        "50": 1.32,
        "100": 0.84,
        "200": 0.575,
        "300": 0.4733,
        "500": 0.386,
        "1000": 0.305,
        "2000": 0.2495,
        "3000": 0.2247,
        "5000": 0.199,
        "10000": 0.1705
    },
    "2x5": {
        "50": 1.4,
        "100": 0.91,
        "200": 0.635,
        "300": 0.53,
        "500": 0.436,
        "1000": 0.349,
        "2000": 0.2885,
        "3000": 0.2613,
        "5000": 0.2322,
        "10000": 0.2045
    },
    "2x6": {
        "50": 1.48,
        "100": 0.97,
        "200": 0.69,
        "300": 0.5833,
        "500": 0.484,
        "1000": 0.392,
        "2000": 0.3265,
        "3000": 0.2963,
        "5000": 0.2642,
        "10000": 0.2405
    },
    "2x7": {
        "50": 1.54,
        "100": 1.03,
        "200": 0.745,
        "300": 0.6333,
        "500": 0.532,
        "1000": 0.433,
        "2000": 0.363,
        "3000": 0.3307,
        "5000": 0.2954,
        "10000": 0.2765
    },
    "2x8": {
        "50": 1.62,
        "100": 1.1,
        "200": 0.8,
        "300": 0.6833,
        "500": 0.578,
        "1000": 0.474,
        "2000": 0.399,
        "3000": 0.3637,
        "5000": 0.3256,
        "10000": 0.3125
    },
    "2x9": {
        "50": 1.68,
        "100": 1.16,
        "200": 0.85,
        "300": 0.7333,
        "500": 0.622,
        "1000": 0.513,
        "2000": 0.434,
        "3000": 0.396,
        "5000": 0.3576,
        "10000": 0.3485
    },
    "2x10": {
        "50": 1.74,
        "100": 1.21,
        "200": 0.905,
        "300": 0.7833,
        "500": 0.666,
        "1000": 0.552,
        "2000": 0.468,
        "3000": 0.428,
        "5000": 0.3936,
        "10000": 0.3845
    },
    "2x11": {
        "50": 1.82,
        "100": 1.27,
        "200": 0.955,
        "300": 0.83,
        "500": 0.71,
        "1000": 0.59,
        "2000": 0.5015,
        "3000": 0.4593,
        "5000": 0.4296,
        "10000": 0.4205
    },
    "2x12": {
        "50": 1.88,
        "100": 1.33,
        "200": 1.005,
        "300": 0.8767,
        "500": 0.752,
        "1000": 0.628,
        "2000": 0.5345,
        "3000": 0.49,
        "5000": 0.4656,
        "10000": 0.4565
    },
    "2x13": {
        "50": 1.94,
        "100": 1.38,
        "200": 1.055,
        "300": 0.9233,
        "500": 0.794,
        "1000": 0.665,
        "2000": 0.5675,
        "3000": 0.5203,
        "5000": 0.5014,
        "10000": 0.4925
    },
    "2x14": {
        "50": 2.0,
        "100": 1.44,
        "200": 1.105,
        "300": 0.9667,
        "500": 0.834,
        "1000": 0.701,
        "2000": 0.5995,
        "3000": 0.55,
        "5000": 0.5376,
        "10000": 0.5285
    },
    "2x15": {
        "50": 2.06,
        "100": 1.49,
        "200": 1.15,
        "300": 1.0133,
        "500": 0.876,
        "1000": 0.737,
        "2000": 0.631,
        "3000": 0.5837,
        "5000": 0.5736,
        "10000": 0.5645
    },
    "2x16": {
        "50": 2.12,
        "100": 1.55,
        "200": 1.2,
        "300": 1.0567,
        "500": 0.916,
        "1000": 0.772,
        "2000": 0.6625,
        "3000": 0.6197,
        "5000": 0.6096,
        "10000": 0.6005
    },
    "2x17": {
        "50": 2.18,
        "100": 1.6,
        "200": 1.245,
        "300": 1.1,
        "500": 0.956,
        "1000": 0.807,
        "2000": 0.6935,
        "3000": 0.6557,
        "5000": 0.6456,
        "10000": 0.6365
    },
    "3x1": {
        "50": 1.12,
        "100": 0.65,
        "200": 0.41,
        "300": 0.3233,
        "500": 0.248,
        "1000": 0.182,
        "2000": 0.1415,
        "3000": 0.1247,
        "5000": 0.1076,
        "10000": 0.0898
    },
    "3x2": {
        "50": 1.24,
        "100": 0.77,
        "200": 0.51,
        "300": 0.4167,
        "500": 0.334,
        "1000": 0.258,
        "2000": 0.2085,
        "3000": 0.1867,
        "5000": 0.1642,
        "10000": 0.1398
    },
    "3x3": {
        "50": 1.36,
        "100": 0.87,
        "200": 0.605,
        "300": 0.5033,
        "500": 0.412,
        "1000": 0.327,
        "2000": 0.2695,
        "3000": 0.2433,
        "5000": 0.2158,
        "10000": 0.1865
    },
    "3x4": {
        "50": 1.48,
        "100": 0.97,
        "200": 0.69,
        "300": 0.5833,
        "500": 0.484,
        "1000": 0.392,
        "2000": 0.3265,
        "3000": 0.2963,
        "5000": 0.2642,
        "10000": 0.2405
    },
    "3x5": {
        "50": 1.58,
        "100": 1.06,
        "200": 0.775,
        "300": 0.66,
        "500": 0.554,
        "1000": 0.454,
        "2000": 0.381,
        "3000": 0.3473,
        "5000": 0.3106,
        "10000": 0.2945
    },
    "3x6": {
        "50": 1.68,
        "100": 1.16,
        "200": 0.85,
        "300": 0.7333,
        "500": 0.622,
        "1000": 0.513,
        "2000": 0.434,
        "3000": 0.396,
        "5000": 0.3576,
        "10000": 0.3485
    },
    "3x7": {
        "50": 1.78,
        "100": 1.24,
        "200": 0.93,
        "300": 0.8067,
        "500": 0.688,
        "1000": 0.571,
        "2000": 0.485,
        "3000": 0.4437,
        "5000": 0.4116,
        "10000": 0.4025
    },
    "3x8": {
        "50": 1.88,
        "100": 1.33,
        "200": 1.005,
        "300": 0.8767,
        "500": 0.752,
        "1000": 0.628,
        "2000": 0.5345,
        "3000": 0.49,
        "5000": 0.4656,
        "10000": 0.4565
    },
    "3x9": {
        "50": 1.98,
        "100": 1.41,
        "200": 1.08,
        "300": 0.9433,
        "500": 0.814,
        "1000": 0.683,
        "2000": 0.5835,
        "3000": 0.5353,
        "5000": 0.5196,
        "10000": 0.5105
    },
    "3x10": {
        "50": 2.06,
        "100": 1.49,
        "200": 1.15,
        "300": 1.0133,
        "500": 0.876,
        "1000": 0.737,
        "2000": 0.631,
        "3000": 0.5837,
        "5000": 0.5736,
        "10000": 0.5645
    },
    "3x11": {
        "50": 2.16,
        "100": 1.57,
        "200": 1.22,
        "300": 1.0767,
        "500": 0.936,
        "1000": 0.79,
        "2000": 0.678,
        "3000": 0.6373,
        "5000": 0.6274,
        "10000": 0.6185
    },
    "3x12": {
        "50": 2.24,
        "100": 1.65,
        "200": 1.29,
        "300": 1.1433,
        "500": 0.994,
        "1000": 0.842,
        "2000": 0.724,
        "3000": 0.6917,
        "5000": 0.6816,
        "10000": 0.6725
    },
    "3x13": {
        "50": 2.34,
        "100": 1.73,
        "200": 1.36,
        "300": 1.2067,
        "500": 1.054,
        "1000": 0.894,
        "2000": 0.7695,
        "3000": 0.7457,
        "5000": 0.7356,
        "10000": 0.7265
    },
    "3x14": {
        "50": 2.42,
        "100": 1.81,
        "200": 1.43,
        "300": 1.27,
        "500": 1.11,
        "1000": 0.944,
        "2000": 0.8145,
        "3000": 0.7997,
        "5000": 0.7896,
        "10000": 0.7805
    },
    "3x15": {
        "50": 2.5,
        "100": 1.88,
        "200": 1.495,
        "300": 1.3333,
        "500": 1.168,
        "1000": 0.995,
        "2000": 0.8645,
        "3000": 0.8537,
        "5000": 0.8436,
        "10000": 0.8345
    },
    "3x16": {
        "50": 2.6,
        "100": 1.96,
        "200": 1.56,
        "300": 1.3933,
        "500": 1.224,
        "1000": 1.044,
        "2000": 0.9185,
        "3000": 0.9077,
        "5000": 0.8976,
        "10000": 0.8885
    },
    "3x17": {
        "50": 2.68,
        "100": 2.03,
        "200": 1.625,
        "300": 1.4533,
        "500": 1.28,
        "1000": 1.093,
        "2000": 0.9725,
        "3000": 0.9617,
        "5000": 0.9516,
        "10000": 0.9425
    },
    "4x1": {
        "50": 1.16,
        "100": 0.69,
        "200": 0.445,
        "300": 0.3567,
        "500": 0.278,
        "1000": 0.209,
        "2000": 0.165,
        "3000": 0.1463,
        "5000": 0.1274,
        "10000": 0.1072
    },
    "4x2": {
        "50": 1.32,
        "100": 0.84,
        "200": 0.575,
        "300": 0.4733,
        "500": 0.386,
        "1000": 0.305,
        "2000": 0.2495,
        "3000": 0.2247,
        "5000": 0.199,
        "10000": 0.1705
    },
    "4x3": {
        "50": 1.48,
        "100": 0.97,
        "200": 0.69,
        "300": 0.5833,
        "500": 0.484,
        "1000": 0.392,
        "2000": 0.3265,
        "3000": 0.2963,
        "5000": 0.2642,
        "10000": 0.2405
    },
    "4x4": {
        "50": 1.62,
        "100": 1.1,
        "200": 0.8,
        "300": 0.6833,
        "500": 0.578,
        "1000": 0.474,
        "2000": 0.399,
        "3000": 0.3637,
        "5000": 0.3256,
        "10000": 0.3125
    },
    "4x5": {
        "50": 1.74,
        "100": 1.21,
        "200": 0.905,
        "300": 0.7833,
        "500": 0.666,
        "1000": 0.552,
        "2000": 0.468,
        "3000": 0.428,
        "5000": 0.3936,
        "10000": 0.3845
    },
    "4x6": {
        "50": 1.88,
        "100": 1.33,
        "200": 1.005,
        "300": 0.8767,
        "500": 0.752,
        "1000": 0.628,
        "2000": 0.5345,
        "3000": 0.49,
        "5000": 0.4656,
        "10000": 0.4565
    },
    "4x7": {
        "50": 2.0,
        "100": 1.44,
        "200": 1.105,
        "300": 0.9667,
        "500": 0.834,
        "1000": 0.701,
        "2000": 0.5995,
        "3000": 0.55,
        "5000": 0.5376,
        "10000": 0.5285
    },
    "4x8": {
        "50": 2.12,
        "100": 1.55,
        "200": 1.2,
        "300": 1.0567,
        "500": 0.916,
        "1000": 0.772,
        "2000": 0.6625,
        "3000": 0.6197,
        "5000": 0.6096,
        "10000": 0.6005
    },
    "4x9": {
        "50": 2.24,
        "100": 1.65,
        "200": 1.29,
        "300": 1.1433,
        "500": 0.994,
        "1000": 0.842,
        "2000": 0.724,
        "3000": 0.6917,
        "5000": 0.6816,
        "10000": 0.6725
    },
    "4x10": {
        "50": 2.36,
        "100": 1.76,
        "200": 1.385,
        "300": 1.2267,
        "500": 1.072,
        "1000": 0.911,
        "2000": 0.798,
        "3000": 0.7637,
        "5000": 0.7534,
        "10000": 0.7445
    },
    "4x11": {
        "50": 2.48,
        "100": 1.86,
        "200": 1.475,
        "300": 1.31,
        "500": 1.148,
        "1000": 0.978,
        "2000": 0.8465,
        "3000": 0.8357,
        "5000": 0.8256,
        "10000": 0.8165
    },
    "4x12": {
        "50": 2.6,
        "100": 1.96,
        "200": 1.56,
        "300": 1.3933,
        "500": 1.224,
        "1000": 1.044,
        "2000": 0.9185,
        "3000": 0.9077,
        "5000": 0.8976,
        "10000": 0.8885
    },
    "4x13": {
        "50": 2.7,
        "100": 2.06,
        "200": 1.65,
        "300": 1.4733,
        "500": 1.298,
        "1000": 1.109,
        "2000": 0.9905,
        "3000": 0.9797,
        "5000": 0.9696,
        "10000": 0.9605
    },
    "4x14": {
        "50": 2.82,
        "100": 2.15,
        "200": 1.735,
        "300": 1.5533,
        "500": 1.37,
        "1000": 1.174,
        "2000": 1.0625,
        "3000": 1.0517,
        "5000": 1.0416,
        "10000": 1.0325
    },
    "4x15": {
        "50": 2.92,
        "100": 2.25,
        "200": 1.77,
        "300": 1.6333,
        "500": 1.442,
        "1000": 1.237,
        "2000": 1.1345,
        "3000": 1.1237,
        "5000": 1.1136,
        "10000": 1.1045
    },
    "4x16": {
        "50": 3.04,
        "100": 2.35,
        "200": 1.905,
        "300": 1.71,
        "500": 1.514,
        "1000": 1.3,
        "2000": 1.2065,
        "3000": 1.1957,
        "5000": 1.1856,
        "10000": 1.1765
    },
    "4x17": {
        "50": 3.14,
        "100": 2.44,
        "200": 1.985,
        "300": 1.7867,
        "500": 1.584,
        "1000": 1.362,
        "2000": 1.2785,
        "3000": 1.0277,
        "5000": 1.2576,
        "10000": 1.2485
    },
    "5x1": {
        "50": 1.2,
        "100": 0.73,
        "200": 0.48,
        "300": 0.3867,
        "500": 0.306,
        "1000": 0.234,
        "2000": 0.187,
        "3000": 0.1667,
        "5000": 0.1462,
        "10000": 0.1238
    },
    "5x2": {
        "50": 1.4,
        "100": 0.91,
        "200": 0.635,
        "300": 0.53,
        "500": 0.436,
        "1000": 0.349,
        "2000": 0.2885,
        "3000": 0.2613,
        "5000": 0.2322,
        "10000": 0.2045
    },
    "5x3": {
        "50": 1.58,
        "100": 1.06,
        "200": 0.775,
        "300": 0.66,
        "500": 0.554,
        "1000": 0.454,
        "2000": 0.381,
        "3000": 0.3473,
        "5000": 0.3106,
        "10000": 0.2945
    },
    "5x4": {
        "50": 1.74,
        "100": 1.21,
        "200": 0.905,
        "300": 0.7833,
        "500": 0.666,
        "1000": 0.552,
        "2000": 0.468,
        "3000": 0.428,
        "5000": 0.3936,
        "10000": 0.3845
    },
    "5x5": {
        "50": 1.9,
        "100": 1.36,
        "200": 1.03,
        "300": 0.9,
        "500": 0.772,
        "1000": 0.646,
        "2000": 0.551,
        "3000": 0.5053,
        "5000": 0.4836,
        "10000": 0.4745
    },
    "5x6": {
        "50": 2.06,
        "100": 1.49,
        "200": 1.15,
        "300": 1.0133,
        "500": 0.876,
        "1000": 0.737,
        "2000": 0.631,
        "3000": 0.5837,
        "5000": 0.5736,
        "10000": 0.5645
    },
    "5x7": {
        "50": 2.22,
        "100": 1.63,
        "200": 1.27,
        "300": 1.12,
        "500": 0.976,
        "1000": 0.825,
        "2000": 0.709,
        "3000": 0.6737,
        "5000": 0.6636,
        "10000": 0.6545
    },
    "5x8": {
        "50": 2.36,
        "100": 1.76,
        "200": 1.385,
        "300": 1.2267,
        "500": 1.072,
        "1000": 0.911,
        "2000": 0.7845,
        "3000": 0.7637,
        "5000": 0.7534,
        "10000": 0.7445
    },
    "5x9": {
        "50": 2.5,
        "100": 1.88,
        "200": 1.495,
        "300": 1.3333,
        "500": 1.168,
        "1000": 0.995,
        "2000": 0.8645,
        "3000": 0.8537,
        "5000": 0.8436,
        "10000": 0.8345
    },
    "5x10": {
        "50": 2.64,
        "100": 2.01,
        "200": 1.605,
        "300": 1.4333,
        "500": 1.26,
        "1000": 1.077,
        "2000": 0.9545,
        "3000": 0.9437,
        "5000": 0.9336,
        "10000": 0.9245
    },
    "5x11": {
        "50": 2.78,
        "100": 2.13,
        "200": 1.715,
        "300": 1.5333,
        "500": 1.352,
        "1000": 1.158,
        "2000": 1.0445,
        "3000": 1.0337,
        "5000": 1.0236,
        "10000": 1.0145
    },
    "5x12": {
        "50": 2.92,
        "100": 2.25,
        "200": 1.82,
        "300": 1.6333,
        "500": 1.442,
        "1000": 1.237,
        "2000": 1.1345,
        "3000": 1.1237,
        "5000": 1.1136,
        "10000": 1.1045
    },
    "5x13": {
        "50": 3.06,
        "100": 2.37,
        "200": 1.925,
        "300": 1.73,
        "500": 1.53,
        "1000": 1.315,
        "2000": 1.2245,
        "3000": 1.2137,
        "5000": 1.2036,
        "10000": 1.1945
    },
    "5x14": {
        "50": 3.18,
        "100": 2.49,
        "200": 2.025,
        "300": 1.8267,
        "500": 1.618,
        "1000": 1.392,
        "2000": 1.3145,
        "3000": 1.3037,
        "5000": 1.2936,
        "10000": 1.2845
    },
    "5x15": {
        "50": 3.32,
        "100": 2.6,
        "200": 2.13,
        "300": 1.92,
        "500": 1.704,
        "1000": 1.469,
        "2000": 1.4045,
        "3000": 1.3937,
        "5000": 1.3836,
        "10000": 1.3745
    },
    "5x16": {
        "50": 3.44,
        "100": 2.72,
        "200": 2.23,
        "300": 2.0133,
        "500": 1.79,
        "1000": 1.544,
        "2000": 1.4945,
        "3000": 1.4837,
        "5000": 1.4736,
        "10000": 1.4645
    },
    "5x17": {
        "50": 3.58,
        "100": 2.83,
        "200": 2.33,
        "300": 2.1067,
        "500": 1.874,
        "1000": 1.618,
        "2000": 1.5845,
        "3000": 1.5737,
        "5000": 1.5634,
        "10000": 1.5545
    },
    "6x1": {
        "50": 1.24,
        "100": 0.77,
        "200": 0.6,
        "300": 0.4167,
        "500": 0.334,
        "1000": 0.258,
        "2000": 0.2085,
        "3000": 0.1867,
        "5000": 0.1642,
        "10000": 0.1398
    },
    "6x2": {
        "50": 1.48,
        "100": 0.97,
        "200": 0.69,
        "300": 0.5833,
        "500": 0.484,
        "1000": 0.392,
        "2000": 0.3265,
        "3000": 0.2963,
        "5000": 0.2642,
        "10000": 0.2405
    },
    "6x3": {
        "50": 1.68,
        "100": 1.16,
        "200": 0.85,
        "300": 0.7333,
        "500": 0.622,
        "1000": 0.513,
        "2000": 0.434,
        "3000": 0.396,
        "5000": 0.3576,
        "10000": 0.3485
    },
    "6x4": {
        "50": 1.88,
        "100": 1.33,
        "200": 1.005,
        "300": 0.8767,
        "500": 0.752,
        "1000": 0.628,
        "2000": 0.5345,
        "3000": 0.49,
        "5000": 0.4656,
        "10000": 0.4565
    },
    "6x5": {
        "50": 2.06,
        "100": 1.49,
        "200": 1.15,
        "300": 1.0133,
        "500": 0.876,
        "1000": 0.737,
        "2000": 0.631,
        "3000": 0.5837,
        "5000": 0.5736,
        "10000": 0.5645
    },
    "6x6": {
        "50": 2.24,
        "100": 1.65,
        "200": 1.29,
        "300": 1.1433,
        "500": 0.958,
        "1000": 0.842,
        "2000": 0.724,
        "3000": 0.6917,
        "5000": 0.6816,
        "10000": 0.6725
    },
    "6x7": {
        "50": 2.42,
        "100": 1.81,
        "200": 1.43,
        "300": 1.27,
        "500": 1.11,
        "1000": 0.944,
        "2000": 0.8145,
        "3000": 0.7997,
        "5000": 0.7896,
        "10000": 0.7805
    },
    "6x8": {
        "50": 2.6,
        "100": 1.96,
        "200": 1.56,
        "300": 1.3933,
        "500": 1.224,
        "1000": 1.044,
        "2000": 0.9185,
        "3000": 0.9077,
        "5000": 0.8976,
        "10000": 0.8885
    },
    "6x9": {
        "50": 2.76,
        "100": 2.11,
        "200": 1.69,
        "300": 1.5133,
        "500": 1.334,
        "1000": 1.142,
        "2000": 1.0265,
        "3000": 1.0157,
        "5000": 1.0056,
        "10000": 0.9965
    },
    "6x10": {
        "50": 2.92,
        "100": 2.25,
        "200": 1.82,
        "300": 1.6333,
        "500": 1.442,
        "1000": 1.237,
        "2000": 1.1345,
        "3000": 1.1237,
        "5000": 1.1136,
        "10000": 1.2845
    },
    "6x11": {
        "50": 3.08,
        "100": 2.39,
        "200": 1.945,
        "300": 1.75,
        "500": 1.548,
        "1000": 1.331,
        "2000": 1.2425,
        "3000": 1.2317,
        "5000": 1.2216,
        "10000": 1.2125
    },
    "6x12": {
        "50": 3.24,
        "100": 2.53,
        "200": 2.07,
        "300": 1.8633,
        "500": 1.652,
        "1000": 1.423,
        "2000": 1.3505,
        "3000": 1.3393,
        "5000": 1.3294,
        "10000": 1.3205
    },
    "6x13": {
        "50": 3.4,
        "100": 2.67,
        "200": 2.19,
        "300": 1.9767,
        "500": 1.756,
        "1000": 1.514,
        "2000": 1.4585,
        "3000": 1.4477,
        "5000": 1.4376,
        "10000": 1.4285
    },
    "6x14": {
        "50": 3.56,
        "100": 2.81,
        "200": 2.31,
        "300": 2.0867,
        "500": 1.858,
        "1000": 1.604,
        "2000": 1.5665,
        "3000": 1.5557,
        "5000": 1.5456,
        "10000": 1.5365
    },
    "6x15": {
        "50": 3.7,
        "100": 2.94,
        "200": 2.425,
        "300": 2.1967,
        "500": 1.958,
        "1000": 1.703,
        "2000": 1.6745,
        "3000": 1.6637,
        "5000": 1.6536,
        "10000": 1.6445
    },
    "6x16": {
        "50": 3.86,
        "100": 3.07,
        "200": 2.545,
        "300": 2.3067,
        "500": 2.056,
        "1000": 1.811,
        "2000": 1.7825,
        "3000": 1.7717,
        "5000": 1.7616,
        "10000": 1.7525
    },
    "6x17": {
        "50": 4.0,
        "100": 3.2,
        "200": 2.66,
        "300": 2.4133,
        "500": 2.154,
        "1000": 1.919,
        "2000": 1.8905,
        "3000": 1.8797,
        "5000": 1.8696,
        "10000": 1.8605
    },
    "7x1": {
        "50": 1.28,
        "100": 0.8,
        "200": 0.545,
        "300": 0.4467,
        "500": 0.36,
        "1000": 0.282,
        "2000": 0.2495,
        "3000": 0.206,
        "5000": 0.1818,
        "10000": 0.1554
    },
    "7x2": {
        "50": 1.54,
        "100": 1.03,
        "200": 0.745,
        "300": 0.6333,
        "500": 0.532,
        "1000": 0.433,
        "2000": 0.363,
        "3000": 0.3307,
        "5000": 0.2954,
        "10000": 0.2765
    },
    "7x3": {
        "50": 1.78,
        "100": 1.24,
        "200": 0.93,
        "300": 0.8067,
        "500": 0.688,
        "1000": 0.571,
        "2000": 0.485,
        "3000": 0.4437,
        "5000": 0.4116,
        "10000": 0.4025
    },
    "7x4": {
        "50": 2.0,
        "100": 1.44,
        "200": 1.105,
        "300": 0.9667,
        "500": 0.834,
        "1000": 0.701,
        "2000": 0.5995,
        "3000": 0.55,
        "5000": 0.5376,
        "10000": 0.5285
    },
    "7x5": {
        "50": 2.22,
        "100": 1.63,
        "200": 1.27,
        "300": 1.12,
        "500": 0.976,
        "1000": 0.825,
        "2000": 0.709,
        "3000": 0.6737,
        "5000": 0.6636,
        "10000": 0.6545
    },
    "7x6": {
        "50": 2.42,
        "100": 1.81,
        "200": 1.43,
        "300": 1.27,
        "500": 1.11,
        "1000": 0.944,
        "2000": 0.8145,
        "3000": 0.7997,
        "5000": 0.7896,
        "10000": 0.7805
    },
    "7x7": {
        "50": 2.62,
        "100": 1.98,
        "200": 1.585,
        "300": 1.4133,
        "500": 1.242,
        "1000": 1.06,
        "2000": 0.9365,
        "3000": 0.9257,
        "5000": 0.9156,
        "10000": 0.9065
    },
    "7x8": {
        "50": 2.82,
        "100": 2.15,
        "200": 1.735,
        "300": 1.5533,
        "500": 1.37,
        "1000": 1.174,
        "2000": 1.0625,
        "3000": 1.0517,
        "5000": 1.0416,
        "10000": 1.0325
    },
    "7x9": {
        "50": 3.0,
        "100": 2.32,
        "200": 1.88,
        "300": 1.69,
        "500": 1.496,
        "1000": 1.284,
        "2000": 1.1885,
        "3000": 1.1777,
        "5000": 1.1676,
        "10000": 1.1585
    },
    "7x10": {
        "50": 3.18,
        "100": 2.49,
        "200": 2.025,
        "300": 1.8267,
        "500": 1.618,
        "1000": 1.392,
        "2000": 1.3145,
        "3000": 1.3037,
        "5000": 1.2936,
        "10000": 1.2485
    },
    "7x11": {
        "50": 3.38,
        "100": 2.65,
        "200": 2.17,
        "300": 1.9567,
        "500": 1.738,
        "1000": 1.499,
        "2000": 1.4405,
        "3000": 1.4297,
        "5000": 1.4178,
        "10000": 1.4105
    },
    "7x12": {
        "50": 3.56,
        "100": 2.81,
        "200": 2.31,
        "300": 2.0867,
        "500": 1.858,
        "1000": 1.604,
        "2000": 1.5665,
        "3000": 1.5557,
        "5000": 1.5456,
        "10000": 1.5365
    },
    "7x13": {
        "50": 3.72,
        "100": 2.96,
        "200": 2.445,
        "300": 2.2167,
        "500": 1.974,
        "1000": 1.721,
        "2000": 1.6925,
        "3000": 1.6817,
        "5000": 1.6716,
        "10000": 1.6625
    },
    "7x14": {
        "50": 3.9,
        "100": 3.12,
        "200": 2.585,
        "300": 2.3433,
        "500": 2.09,
        "1000": 1.847,
        "2000": 1.8185,
        "3000": 1.8077,
        "5000": 1.7976,
        "10000": 1.7885
    },
    "7x15": {
        "50": 4.08,
        "100": 3.27,
        "200": 2.715,
        "300": 2.4667,
        "500": 2.204,
        "1000": 1.973,
        "2000": 1.9445,
        "3000": 1.9337,
        "5000": 1.9236,
        "10000": 1.9145
    },
    "7x16": {
        "50": 4.24,
        "100": 3.42,
        "200": 2.85,
        "300": 2.59,
        "500": 2.316,
        "1000": 2.099,
        "2000": 2.0705,
        "3000": 2.0597,
        "5000": 2.0496,
        "10000": 2.0405
    },
    "7x17": {
        "50": 4.42,
        "100": 3.57,
        "200": 2.98,
        "300": 2.7133,
        "500": 2.426,
        "1000": 2.225,
        "2000": 2.1965,
        "3000": 2.1857,
        "5000": 2.1756,
        "10000": 2.1665
    },
    "8x1": {
        "50": 1.32,
        "100": 0.84,
        "200": 0.575,
        "300": 0.4733,
        "500": 0.386,
        "1000": 0.305,
        "2000": 0.2495,
        "3000": 0.2247,
        "5000": 0.199,
        "10000": 0.1705
    },
    "8x2": {
        "50": 1.62,
        "100": 1.1,
        "200": 0.8,
        "300": 0.6833,
        "500": 0.578,
        "1000": 0.474,
        "2000": 0.399,
        "3000": 0.3637,
        "5000": 0.3256,
        "10000": 0.3125
    },
    "8x3": {
        "50": 1.88,
        "100": 1.33,
        "200": 1.005,
        "300": 0.8767,
        "500": 0.752,
        "1000": 0.628,
        "2000": 0.5345,
        "3000": 0.49,
        "5000": 0.4656,
        "10000": 0.4565
    },
    "8x4": {
        "50": 2.12,
        "100": 1.55,
        "200": 1.2,
        "300": 1.0567,
        "500": 0.916,
        "1000": 0.772,
        "2000": 0.6625,
        "3000": 0.6197,
        "5000": 0.6096,
        "10000": 0.6005
    },
    "8x5": {
        "50": 2.36,
        "100": 1.76,
        "200": 1.385,
        "300": 1.2267,
        "500": 1.072,
        "1000": 0.911,
        "2000": 0.7845,
        "3000": 0.7637,
        "5000": 0.7534,
        "10000": 0.7445
    },
    "8x6": {
        "50": 2.6,
        "100": 1.96,
        "200": 1.56,
        "300": 1.3933,
        "500": 1.23,
        "1000": 1.044,
        "2000": 0.9185,
        "3000": 0.9077,
        "5000": 0.8976,
        "10000": 0.8885
    },
    "8x7": {
        "50": 2.82,
        "100": 2.15,
        "200": 1.735,
        "300": 1.5533,
        "500": 1.37,
        "1000": 1.174,
        "2000": 1.0625,
        "3000": 1.0517,
        "5000": 1.0416,
        "10000": 1.0325
    },
    "8x8": {
        "50": 3.04,
        "100": 2.35,
        "200": 1.905,
        "300": 1.71,
        "500": 1.514,
        "1000": 1.3,
        "2000": 1.2065,
        "3000": 1.1937,
        "5000": 1.1856,
        "10000": 1.1765
    },
    "8x9": {
        "50": 3.24,
        "100": 2.53,
        "200": 2.07,
        "300": 1.8633,
        "500": 1.652,
        "1000": 1.423,
        "2000": 1.3505,
        "3000": 1.3393,
        "5000": 1.3294,
        "10000": 1.3205
    },
    "8x10": {
        "50": 3.44,
        "100": 2.72,
        "200": 2.23,
        "300": 2.0133,
        "500": 1.79,
        "1000": 1.544,
        "2000": 1.4945,
        "3000": 1.4837,
        "5000": 1.4736,
        "10000": 1.4645
    },
    "8x11": {
        "50": 3.66,
        "100": 2.89,
        "200": 2.39,
        "300": 2.16,
        "500": 1.924,
        "1000": 1.667,
        "2000": 1.6385,
        "3000": 1.6277,
        "5000": 1.6176,
        "10000": 1.6085
    },
    "8x12": {
        "50": 3.86,
        "100": 3.07,
        "200": 2.545,
        "300": 2.3067,
        "500": 2.056,
        "1000": 1.811,
        "2000": 1.7825,
        "3000": 1.7717,
        "5000": 1.7616,
        "10000": 1.7525
    },
    "8x13": {
        "50": 4.06,
        "100": 3.25,
        "200": 2.7,
        "300": 2.45,
        "500": 2.188,
        "1000": 1.955,
        "2000": 1.9265,
        "3000": 1.9157,
        "5000": 1.9056,
        "10000": 1.8965
    },
    "8x14": {
        "50": 4.24,
        "100": 3.42,
        "200": 2.85,
        "300": 2.59,
        "500": 2.316,
        "1000": 2.099,
        "2000": 2.0705,
        "3000": 2.0597,
        "5000": 2.0496,
        "10000": 2.0405
    },
    "8x15": {
        "50": 4.44,
        "100": 3.59,
        "200": 3.0,
        "300": 2.73,
        "500": 2.442,
        "1000": 2.243,
        "2000": 2.2145,
        "3000": 2.2037,
        "5000": 2.1934,
        "10000": 2.1845
    },
    "8x16": {
        "50": 4.62,
        "100": 3.75,
        "200": 3.145,
        "300": 2.8667,
        "500": 2.568,
        "1000": 2.387,
        "2000": 2.3585,
        "3000": 2.3477,
        "5000": 2.3376,
        "10000": 2.3285
    },
    "8x17": {
        "50": 4.82,
        "100": 3.92,
        "200": 3.295,
        "300": 3.0033,
        "500": 2.692,
        "1000": 2.531,
        "2000": 2.5025,
        "3000": 2.4913,
        "5000": 2.4816,
        "10000": 2.4725
    },
    "9x1": {
        "50": 1.36,
        "100": 0.87,
        "200": 0.605,
        "300": 0.5033,
        "500": 0.412,
        "1000": 0.327,
        "2000": 0.2695,
        "3000": 0.2433,
        "5000": 0.2158,
        "10000": 0.1865
    },
    "9x2": {
        "50": 1.68,
        "100": 1.16,
        "200": 0.85,
        "300": 0.7333,
        "500": 0.622,
        "1000": 0.513,
        "2000": 0.434,
        "3000": 0.396,
        "5000": 0.3576,
        "10000": 0.3485
    },
    "9x3": {
        "50": 1.98,
        "100": 1.41,
        "200": 1.08,
        "300": 0.9433,
        "500": 0.814,
        "1000": 0.683,
        "2000": 0.5835,
        "3000": 0.5353,
        "5000": 0.5196,
        "10000": 0.5105
    },
    "9x4": {
        "50": 2.24,
        "100": 1.65,
        "200": 1.29,
        "300": 1.1433,
        "500": 0.994,
        "1000": 0.842,
        "2000": 0.724,
        "3000": 0.6917,
        "5000": 0.6816,
        "10000": 0.6725
    },
    "9x5": {
        "50": 2.5,
        "100": 1.88,
        "200": 1.495,
        "300": 1.3333,
        "500": 1.168,
        "1000": 0.995,
        "2000": 0.8645,
        "3000": 0.8537,
        "5000": 0.8436,
        "10000": 0.8345
    },
    "9x6": {
        "50": 2.76,
        "100": 2.11,
        "200": 1.69,
        "300": 1.5133,
        "500": 1.334,
        "1000": 1.142,
        "2000": 1.0265,
        "3000": 1.0157,
        "5000": 1.0056,
        "10000": 0.9965
    },
    "9x7": {
        "50": 3.0,
        "100": 2.32,
        "200": 1.88,
        "300": 1.69,
        "500": 1.496,
        "1000": 1.284,
        "2000": 1.1885,
        "3000": 1.1777,
        "5000": 1.1676,
        "10000": 1.1585
    },
    "9x8": {
        "50": 3.24,
        "100": 2.53,
        "200": 2.07,
        "300": 1.8633,
        "500": 1.652,
        "1000": 1.423,
        "2000": 1.3505,
        "3000": 1.3393,
        "5000": 1.3294,
        "10000": 1.3205
    },
    "9x9": {
        "50": 3.48,
        "100": 2.74,
        "200": 2.25,
        "300": 2.0333,
        "500": 1.806,
        "1000": 1.559,
        "2000": 1.5125,
        "3000": 1.5017,
        "5000": 1.4916,
        "10000": 1.4825
    },
    "9x10": {
        "50": 3.7,
        "100": 2.94,
        "200": 2.425,
        "300": 2.1967,
        "500": 1.958,
        "1000": 1.703,
        "2000": 1.6745,
        "3000": 1.6637,
        "5000": 1.6536,
        "10000": 1.6445
    },
    "9x11": {
        "50": 3.92,
        "100": 3.14,
        "200": 2.6,
        "300": 2.36,
        "500": 2.106,
        "1000": 1.865,
        "2000": 1.8365,
        "3000": 1.8257,
        "5000": 1.8156,
        "10000": 1.8065
    },
    "9x12": {
        "50": 4.14,
        "100": 3.33,
        "200": 2.775,
        "300": 2.52,
        "500": 2.252,
        "1000": 2.027,
        "2000": 1.9985,
        "3000": 1.9877,
        "5000": 1.9776,
        "10000": 1.9685
    },
    "9x13": {
        "50": 4.36,
        "100": 3.52,
        "200": 2.945,
        "300": 2.6767,
        "500": 2.396,
        "1000": 2.189,
        "2000": 2.1605,
        "3000": 2.1497,
        "5000": 2.1396,
        "10000": 2.1305
    },
    "9x14": {
        "50": 4.58,
        "100": 3.71,
        "200": 3.11,
        "300": 2.8333,
        "500": 2.536,
        "1000": 2.351,
        "2000": 2.3225,
        "3000": 2.3117,
        "5000": 2.3016,
        "10000": 2.2925
    },
    "9x15": {
        "50": 4.8,
        "100": 3.8,
        "200": 3.275,
        "300": 2.9867,
        "500": 2.676,
        "1000": 2.513,
        "2000": 2.4845,
        "3000": 2.4737,
        "5000": 2.4634,
        "10000": 2.4545
    },
    "9x16": {
        "50": 5.0,
        "100": 4.08,
        "200": 3.44,
        "300": 3.1367,
        "500": 2.814,
        "1000": 2.675,
        "2000": 2.6465,
        "3000": 2.6357,
        "5000": 2.6256,
        "10000": 2.6165
    },
    "9x17": {
        "50": 5.2,
        "100": 4.27,
        "200": 3.6,
        "300": 3.2867,
        "500": 2.952,
        "1000": 2.837,
        "2000": 2.8085,
        "3000": 2.7977,
        "5000": 2.7876,
        "10000": 2.7785
    },
    "10x1": {
        "50": 1.4,
        "100": 0.91,
        "200": 0.635,
        "300": 0.53,
        "500": 0.436,
        "1000": 0.349,
        "2000": 0.2885,
        "3000": 0.2613,
        "5000": 0.2322,
        "10000": 0.2045
    },
    "10x2": {
        "50": 1.74,
        "100": 1.21,
        "200": 0.905,
        "300": 0.7833,
        "500": 0.666,
        "1000": 0.552,
        "2000": 0.468,
        "3000": 0.428,
        "5000": 0.3936,
        "10000": 0.3845
    },
    "10x3": {
        "50": 2.06,
        "100": 1.49,
        "200": 1.15,
        "300": 1.0133,
        "500": 0.876,
        "1000": 0.737,
        "2000": 0.631,
        "3000": 0.5837,
        "5000": 0.5736,
        "10000": 0.5645
    },
    "10x4": {
        "50": 2.36,
        "100": 1.76,
        "200": 1.385,
        "300": 1.2267,
        "500": 1.072,
        "1000": 0.911,
        "2000": 0.7845,
        "3000": 0.7637,
        "5000": 0.7534,
        "10000": 0.7445
    },
    "10x5": {
        "50": 2.64,
        "100": 2.01,
        "200": 1.605,
        "300": 1.4333,
        "500": 1.26,
        "1000": 1.077,
        "2000": 0.9545,
        "3000": 0.9437,
        "5000": 0.9336,
        "10000": 0.9245
    },
    "10x6": {
        "50": 2.92,
        "100": 2.25,
        "200": 1.82,
        "300": 1.6333,
        "500": 1.442,
        "1000": 1.237,
        "2000": 1.1345,
        "3000": 1.1237,
        "5000": 1.1136,
        "10000": 1.2845
    },
    "10x7": {
        "50": 3.18,
        "100": 2.49,
        "200": 2.025,
        "300": 1.8267,
        "500": 1.618,
        "1000": 1.392,
        "2000": 1.3145,
        "3000": 1.3037,
        "5000": 1.2936,
        "10000": 1.2845
    },
    "10x8": {
        "50": 3.44,
        "100": 2.72,
        "200": 2.23,
        "300": 2.0133,
        "500": 1.79,
        "1000": 1.544,
        "2000": 1.4945,
        "3000": 1.4837,
        "5000": 1.4736,
        "10000": 1.4645
    },
    "10x9": {
        "50": 3.7,
        "100": 2.84,
        "200": 2.425,
        "300": 2.1967,
        "500": 1.958,
        "1000": 1.703,
        "2000": 1.6745,
        "3000": 1.6637,
        "5000": 1.6536,
        "10000": 1.6455
    },
    "10x10": {
        "50": 3.96,
        "100": 3.16,
        "200": 2.62,
        "300": 2.3767,
        "500": 2.122,
        "1000": 1.883,
        "2000": 1.8545,
        "3000": 1.8437,
        "5000": 1.8336,
        "10000": 1.8245
    },
    "10x11": {
        "50": 4.2,
        "100": 3.37,
        "200": 2.81,
        "300": 2.5567,
        "500": 2.284,
        "1000": 2.063,
        "2000": 2.0345,
        "3000": 2.0237,
        "5000": 2.0136,
        "10000": 2.0045
    },
    "10x12": {
        "50": 4.44,
        "100": 3.59,
        "200": 3.0,
        "300": 2.73,
        "500": 2.442,
        "1000": 2.243,
        "2000": 2.2145,
        "3000": 2.2037,
        "5000": 2.1934,
        "10000": 2.1845
    },
    "10x13": {
        "50": 4.68,
        "100": 3.8,
        "200": 3.185,
        "300": 2.9,
        "500": 2.598,
        "1000": 2.423,
        "2000": 2.3945,
        "3000": 2.3837,
        "5000": 2.3736,
        "10000": 2.3645
    },
    "10x14": {
        "50": 4.9,
        "100": 4.0,
        "200": 3.365,
        "300": 3.07,
        "500": 2.754,
        "1000": 2.603,
        "2000": 2.5745,
        "3000": 2.5637,
        "5000": 2.5536,
        "10000": 2.5445
    },
    "10x15": {
        "50": 5.14,
        "100": 4.21,
        "200": 3.545,
        "300": 3.2367,
        "500": 2.906,
        "1000": 2.783,
        "2000": 2.7545,
        "3000": 2.7437,
        "5000": 2.7336,
        "10000": 2.7254
    },
    "10x16": {
        "50": 5.36,
        "100": 4.11,
        "200": 3.725,
        "300": 3.4033,
        "500": 3.056,
        "1000": 2.963,
        "2000": 2.9345,
        "3000": 2.9237,
        "5000": 2.9172,
        "10000": 2.9045
    },
    "10x17": {
        "50": 5.6,
        "100": 4.61,
        "200": 3.9,
        "300": 3.5667,
        "500": 3.206,
        "1000": 3.143,
        "2000": 3.1145,
        "3000": 3.1037,
        "5000": 3.0936,
        "10000": 3.0845
    }
  // Add more sizes as needed
};

function findClosestQuantity(quantity: number): number {
  const standardQuantities = [50, 100, 200, 300, 500, 1000, 2000, 3000, 5000, 10000];
  return standardQuantities.reduce((prev, curr) => {
    return Math.abs(curr - quantity) < Math.abs(prev - quantity) ? curr : prev;
  });
}

function getSizeKey(width: number, height: number): string {
  // Always put smaller dimension first to match pricing data format
  const [smaller, larger] = [width, height].sort((a, b) => a - b);
  return `${smaller}x${larger}`;
}

export function calculatePrice(width: number, height: number, quantity: number): number {
  try {
    // Ensure minimum quantity
    if (quantity < 50) {
      quantity = 50;
    }

    const sizeKey = getSizeKey(width, height);
    const closestQuantity = findClosestQuantity(quantity);

    // Get pricing for exact size match
    if (pricingData[sizeKey] && pricingData[sizeKey][closestQuantity]) {
      const pricePerSticker = pricingData[sizeKey][closestQuantity];
      return Number((pricePerSticker * quantity).toFixed(2));
    }

    // If no exact match, calculate based on area
    const area = width * height;
    let closestSizeKey = Object.keys(pricingData)[0];
    let smallestDiff = Infinity;

    for (const key of Object.keys(pricingData)) {
      const [w, h] = key.split('x').map(Number);
      const currentArea = w * h;
      const diff = Math.abs(currentArea - area);
      
      if (diff < smallestDiff) {
        smallestDiff = diff;
        closestSizeKey = key;
      }
    }

    const pricePerSticker = pricingData[closestSizeKey][closestQuantity];
    const areaRatio = area / (Number(closestSizeKey.split('x')[0]) * Number(closestSizeKey.split('x')[1]));
    return Number((pricePerSticker * quantity * areaRatio).toFixed(2));

  } catch (error) {
    console.error('Error calculating price:', error);
    toast.error('Error calculating price');
    return 0;
  }
}

export function formatPrice(price: number): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(price);
}
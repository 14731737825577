import { useState, useEffect } from 'react';
import { X, ShoppingCart } from 'lucide-react';
import { useSticker } from '../context/StickerContext';
import { calculatePrice, formatPrice } from '../utils/pricing';
import { Button } from './ui/button';
import { Label } from './ui/label';
import NumberInput from './NumberInput';
import toast from 'react-hot-toast';
import { initiateCheckout } from '../utils/stripe';
import { getCanvasDataURL } from '../utils/canvas/exportHandlers';
import { useAnalytics } from '../context/AnalyticsContext';
import { trackEvents, trackStickerDesign } from '../utils/analytics';
import { fabric } from 'fabric';
import ContactForm, { ContactFormData } from './ContactForm';
import { useCheckout } from '../context/CheckoutContext';

interface CheckoutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

type CheckoutStep = 'order-details' | 'contact-info';

export default function CheckoutModal({ isOpen, onClose }: CheckoutModalProps) {
  const { 
    size, 
    quantity, 
    updateQuantity,
    finish,
    updateFinish,
    canvasShape,
  } = useSticker();
  const { analytics } = useAnalytics();
  const { setCheckoutOpen } = useCheckout();
  const [isProcessing, setIsProcessing] = useState(false);
  const [previewUrl, setPreviewUrl] = useState<string>('');
  const [currentStep, setCurrentStep] = useState<CheckoutStep>('order-details');
  const [contactData, setContactData] = useState<ContactFormData | null>(null);

  const price = calculatePrice(size.width, size.height, quantity);
  const pricePerSticker = price / quantity;

  const handleQuantityBlur = (value: number) => {
    if (value < 50) {
      updateQuantity(50);
      toast.error('Minimum quantity is 50');
      return;
    }

    trackStickerDesign(analytics, trackEvents.QUANTITY_CHANGED, {
      width: size.width,
      height: size.height,
      shape: canvasShape,
      finish,
      quantity: value,
      price
    });
  };

  const handleFinishChange = (newFinish: 'matte' | 'glossy') => {
    updateFinish(newFinish);
    trackStickerDesign(analytics, trackEvents.FINISH_CHANGED, {
      width: size.width,
      height: size.height,
      shape: canvasShape,
      finish: newFinish,
      quantity,
      price
    });
  };

  const handlePurchase = async () => {
    if (isProcessing) return;

    try {
      setIsProcessing(true);
      
      const canvas = (window as any).fabricCanvas as fabric.Canvas;
      if (!canvas) {
        throw new Error('Canvas not found');
      }

      const finalDesignUrl = await getCanvasDataURL(canvas);
      if (!finalDesignUrl) {
        throw new Error('Failed to generate design preview');
      }

      trackStickerDesign(analytics, trackEvents.CHECKOUT_STARTED, {
        width: size.width,
        height: size.height,
        shape: canvasShape,
        finish,
        quantity,
        price
      });

      const checkoutItem = {
        name: 'Custom Sticker',
        price: price,
        quantity: quantity,
        size: `${size.width}" × ${size.height}"`,
        finish: finish,
        shape: canvasShape,
        image_url: finalDesignUrl,
        // Add contact info to the checkout metadata
        customer_email: contactData?.email,
        customer_phone: contactData?.phone,
        shipping_address: contactData ? {
          street: contactData.shippingAddress.street,
          city: contactData.shippingAddress.city,
          state: contactData.shippingAddress.state,
          zip: contactData.shippingAddress.zipCode,
        } : undefined,
        billing_address: contactData ? {
          street: contactData.billingAddress.street,
          city: contactData.billingAddress.city,
          state: contactData.billingAddress.state,
          zip: contactData.billingAddress.zipCode,
        } : undefined,
      };

      await initiateCheckout([checkoutItem]);
    } catch (err) {
      console.error('Checkout error:', err);
      toast.error('Failed to start checkout. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleContinueToContact = () => {
    setCurrentStep('contact-info');
  };

  const handleBackToOrder = () => {
    setCurrentStep('order-details');
  };

  const handleContactSubmit = (data: ContactFormData) => {
    setContactData(data);
    handlePurchase();
  };

  const handleClose = () => {
    setCheckoutOpen(false);
    onClose();
  };

  useEffect(() => {
    setCheckoutOpen(isOpen);
  }, [isOpen, setCheckoutOpen]);

  useEffect(() => {
    if (!isOpen) return;

    const generatePreview = async () => {
      try {
        const canvas = (window as any).fabricCanvas as fabric.Canvas;
        if (!canvas) {
          throw new Error('Canvas not found');
        }

        const url = await getCanvasDataURL(canvas);
        if (!url) {
          throw new Error('Failed to generate preview');
        }

        setPreviewUrl(url);
      } catch (err) {
        console.error('Error generating preview:', err);
        toast.error('Failed to generate preview');
      }
    };

    generatePreview();
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black/50" onClick={handleClose} />
      <div className="relative bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div className="sticky top-0 bg-white border-b border-gray-200 px-6 py-4 flex justify-between items-center">
          <h2 className="text-xl font-semibold">
            {currentStep === 'order-details' ? 'Order Details' : 'Contact Information'}
          </h2>
          <button
            onClick={handleClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="p-6">
          {currentStep === 'order-details' ? (
            <div className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="space-y-4 order-2 md:order-1">
                  <div>
                    <Label>Size</Label>
                    <p className="text-gray-700">{size.width}" × {size.height}"</p>
                  </div>

                  <div>
                    <Label>Shape</Label>
                    <p className="text-gray-700 capitalize">{canvasShape}</p>
                  </div>

                  <div>
                    <Label>Finish</Label>
                    <div className="flex gap-4 mt-1">
                      <button
                        onClick={() => handleFinishChange('matte')}
                        className={`px-4 py-2 rounded-lg border ${
                          finish === 'matte'
                            ? 'border-blue-500 bg-blue-50 text-blue-700'
                            : 'border-gray-200 hover:border-gray-300'
                        }`}
                      >
                        Matte
                      </button>
                      <button
                        onClick={() => handleFinishChange('glossy')}
                        className={`px-4 py-2 rounded-lg border ${
                          finish === 'glossy'
                            ? 'border-blue-500 bg-blue-50 text-blue-700'
                            : 'border-gray-200 hover:border-gray-300'
                        }`}
                      >
                        Glossy
                      </button>
                    </div>
                  </div>

                  <div>
                    <Label>Quantity</Label>
                    <div className="mt-1">
                      <NumberInput
                        value={quantity}
                        onChange={updateQuantity}
                        onBlur={handleQuantityBlur}
                        min={50}
                        step={10}
                      />
                      <p className="text-sm text-gray-500 mt-1">
                        Minimum order quantity: 50
                      </p>
                    </div>
                  </div>

                  <div className="pt-4 border-t">
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-lg font-semibold">Total Price</p>
                        <p className="text-sm text-gray-500">
                          {formatPrice(pricePerSticker)} per sticker
                        </p>
                      </div>
                      <p className="text-2xl font-bold">{formatPrice(price)}</p>
                    </div>
                  </div>
                </div>

                <div className="order-1 md:order-2">
                  <Label className="block mb-2">Preview</Label>
                  <div className="relative aspect-square w-full rounded-lg overflow-hidden border bg-white">
                    {previewUrl ? (
                      <img 
                        src={previewUrl} 
                        alt="Sticker Preview"
                        className="w-full h-full object-contain p-4"
                      />
                    ) : (
                      <div className="absolute inset-0 flex items-center justify-center">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900"></div>
                      </div>
                    )}
                  </div>
                  <p className="text-sm text-gray-500 mt-2 text-center">
                    This is how your sticker will look when printed
                  </p>
                </div>
              </div>

              <div className="flex justify-end">
                <Button
                  onClick={handleContinueToContact}
                  className="w-full sm:w-auto"
                >
                  <ShoppingCart className="h-4 w-4 mr-2" />
                  Continue to Contact Info
                </Button>
              </div>
            </div>
          ) : (
            <ContactForm
              onSubmit={handleContactSubmit}
              onBack={handleBackToOrder}
            />
          )}
        </div>
      </div>
    </div>
  );
}
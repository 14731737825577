import { useState } from 'react';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Button } from './ui/button';

interface ContactFormProps {
  onSubmit: (data: ContactFormData) => void;
  onBack: () => void;
}

export interface ContactFormData {
  email: string;
  phone: string;
  shippingAddress: {
    street: string;
    city: string;
    state: string;
    zipCode: string;
  };
  billingAddress: {
    street: string;
    city: string;
    state: string;
    zipCode: string;
  };
  sameAsShipping: boolean;
}

export default function ContactForm({ onSubmit, onBack }: ContactFormProps) {
  const [formData, setFormData] = useState<ContactFormData>({
    email: '',
    phone: '',
    shippingAddress: {
      street: '',
      city: '',
      state: '',
      zipCode: '',
    },
    billingAddress: {
      street: '',
      city: '',
      state: '',
      zipCode: '',
    },
    sameAsShipping: true,
  });

  const handleInputChange = (field: string, value: string) => {
    if (field.includes('.')) {
      const [address, subfield] = field.split('.');
      setFormData(prev => ({
        ...prev,
        [address]: {
          ...prev[address as keyof Pick<ContactFormData, 'shippingAddress' | 'billingAddress'>],
          [subfield]: value,
        },
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  const handleSameAsShippingChange = (checked: boolean) => {
    setFormData(prev => ({
      ...prev,
      sameAsShipping: checked,
      billingAddress: checked ? prev.shippingAddress : prev.billingAddress,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="space-y-4">
        <div>
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            type="email"
            value={formData.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
            required
          />
        </div>

        <div>
          <Label htmlFor="phone">Phone Number</Label>
          <Input
            id="phone"
            type="tel"
            value={formData.phone}
            onChange={(e) => handleInputChange('phone', e.target.value)}
            required
          />
        </div>

        <div className="border-t pt-4">
          <h3 className="font-semibold mb-4">Shipping Address</h3>
          <div className="space-y-2">
            <div>
              <Label htmlFor="shipping-street">Street Address</Label>
              <Input
                id="shipping-street"
                value={formData.shippingAddress.street}
                onChange={(e) => handleInputChange('shippingAddress.street', e.target.value)}
                required
              />
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <Label htmlFor="shipping-city">City</Label>
                <Input
                  id="shipping-city"
                  value={formData.shippingAddress.city}
                  onChange={(e) => handleInputChange('shippingAddress.city', e.target.value)}
                  required
                />
              </div>
              <div>
                <Label htmlFor="shipping-state">State</Label>
                <Input
                  id="shipping-state"
                  value={formData.shippingAddress.state}
                  onChange={(e) => handleInputChange('shippingAddress.state', e.target.value)}
                  required
                />
              </div>
            </div>
            <div>
              <Label htmlFor="shipping-zip">ZIP Code</Label>
              <Input
                id="shipping-zip"
                value={formData.shippingAddress.zipCode}
                onChange={(e) => handleInputChange('shippingAddress.zipCode', e.target.value)}
                required
              />
            </div>
          </div>
        </div>

        <div className="flex items-center space-x-2 my-4">
          <input
            type="checkbox"
            id="same-as-shipping"
            checked={formData.sameAsShipping}
            onChange={(e) => handleSameAsShippingChange(e.target.checked)}
            className="h-4 w-4 rounded border-gray-300"
          />
          <Label htmlFor="same-as-shipping">Billing address same as shipping</Label>
        </div>

        {!formData.sameAsShipping && (
          <div className="border-t pt-4">
            <h3 className="font-semibold mb-4">Billing Address</h3>
            <div className="space-y-2">
              <div>
                <Label htmlFor="billing-street">Street Address</Label>
                <Input
                  id="billing-street"
                  value={formData.billingAddress.street}
                  onChange={(e) => handleInputChange('billingAddress.street', e.target.value)}
                  required
                />
              </div>
              <div className="grid grid-cols-2 gap-2">
                <div>
                  <Label htmlFor="billing-city">City</Label>
                  <Input
                    id="billing-city"
                    value={formData.billingAddress.city}
                    onChange={(e) => handleInputChange('billingAddress.city', e.target.value)}
                    required
                  />
                </div>
                <div>
                  <Label htmlFor="billing-state">State</Label>
                  <Input
                    id="billing-state"
                    value={formData.billingAddress.state}
                    onChange={(e) => handleInputChange('billingAddress.state', e.target.value)}
                    required
                  />
                </div>
              </div>
              <div>
                <Label htmlFor="billing-zip">ZIP Code</Label>
                <Input
                  id="billing-zip"
                  value={formData.billingAddress.zipCode}
                  onChange={(e) => handleInputChange('billingAddress.zipCode', e.target.value)}
                  required
                />
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-between pt-4">
        <Button type="button" variant="outline" onClick={onBack}>
          Back
        </Button>
        <Button type="submit">
          Continue to Payment
        </Button>
      </div>
    </form>
  );
}

import React, { useState } from 'react';
import { ShoppingCart, Menu, X } from 'lucide-react';
import CheckoutModal from './CheckoutModal';
import DownloadButton from './DownloadButton';
import EditableTitle from './EditableTitle';
import { Button } from './ui/button';
import { useWindow } from '../hooks/useWindow';
import { useSticker } from '../context/StickerContext';

interface NavbarProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (open: boolean) => void;
  setIsModalOpen: (open: boolean) => void;
}

const CarrcoLabelsLogo = () => (
  <img 
    src="https://pub-d49ad981336d46b489310e32617d4460.r2.dev/CARRCO-labels-logo-CROPPED.png"
    alt="Carrco Labels" 
    className="h-8"
  />
);

export default function Navbar({ 
  isSidebarOpen, 
  setIsSidebarOpen, 
  setIsModalOpen 
}: NavbarProps) {
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const { isMobile } = useWindow();
  const { designName } = useSticker();

  const handleCheckout = () => {
    setIsCheckoutOpen(true);
    setIsModalOpen(true);
  };

  const handleCloseCheckout = () => {
    setIsCheckoutOpen(false);
    setIsModalOpen(false);
  };

  return (
    <>
      <nav className="fixed top-0 left-0 right-0 z-50 bg-white border-b border-gray-200 h-14">
        <div className="h-full max-w-[1920px] mx-auto px-4">
          <div className="flex justify-between items-center h-full">
            <div className="flex items-center space-x-4">
              <Button
                variant="ghost"
                size="icon"
                className="lg:hidden flex items-center justify-center h-9"
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              >
                {isSidebarOpen ? (
                  <X className="h-5 w-5" />
                ) : (
                  <Menu className="h-5 w-5" />
                )}
              </Button>

              <div className="flex items-center">
                <CarrcoLabelsLogo />
              </div>
            </div>

            {!isMobile && (
              <EditableTitle className="absolute left-1/2 transform -translate-x-1/2" />
            )}
            
            <div className="flex items-center space-x-2 sm:space-x-4">
              <DownloadButton />

              <Button
                variant="default"
                size="sm"
                onClick={handleCheckout}
                title="Confirm & Purchase"
                className="h-9 px-2 sm:px-3 flex items-center"
              >
                <ShoppingCart className="w-4 h-4 sm:mr-2" />
                <span className="hidden sm:inline">Purchase</span>
              </Button>
            </div>
          </div>
        </div>
      </nav>

      <CheckoutModal 
        isOpen={isCheckoutOpen}
        onClose={handleCloseCheckout}
      />
    </>
  );
}